 /* ##
  * # 
  * # Add here your custom script(s)
  * # 
  * # -- Last Script added : 02 / 08 / 2024
  * ## ===================================== ## */

jQuery(document).ready(function($){
    /** Adjust main content area margin top based on header height */
    $('#et-main-area').css("marginTop", $('header.et-l .et_pb_section--fixed').outerHeight());

    /** Relocate Call Us icon next to burger menu in mobile devices */
    $('.gm-navbar .gm-container').append($('#bh-callus-menu'));
})
